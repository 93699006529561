import React from 'react'

export const PageNotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen">
      <h1 className="h3 leading-xdisplay uppercase md:text-300">
        404
      </h1>
      <h2 className="heading-sm text-center">Oops, page not found</h2>
    </div>
  )
}
