import { graphql, useStaticQuery } from 'gatsby'

export const usePatents = () => {
  const data = useStaticQuery(graphql`
    query {
      patentPage: sanityPatentPage {
        seo {
          title
          description
          image {
            asset {
              url
            }
          }
        }
        title
        slug {
          current
        }
        subtitle
        _rawContent
      }
    }
  `)
  return data || {}
}
