import React from 'react'
import Meta from '../components/Meta'
// import BlogCard from '../components/BlogCard'
// import Hero from '../components/Hero'
import { PageNotFound } from '../components/PageNotFound'
import { usePatents } from '../hooks/usePatents'
import { PortableText } from '../components/PortableText'

const Patent = () => {
  const { patentPage } = usePatents()
  if (!patentPage) return <PageNotFound />
  const { seo, title, subtitle, _rawContent } = patentPage

  return (
    <>
      <Meta pageTitle="Patents" props={seo} />
      <div className="pt-24 md:header-padding px-[4%] md:px-[6%]">
        <div className="w-full pt-24 pb-12 md:header-padding relative md:pb-[5%]">
          <div className="blog rich-text md:mt-auto md:pb-[2%] mb-[4%]">
            <h1 className="h3 pt-5 pb-3 md:py-0 md:pb-7">{title}</h1>
            {subtitle && <p className='body'>{subtitle}</p>}
          </div>
          <PortableText className="blog rich-text" blocks={_rawContent} />
        </div>
      </div>
    </>
  )
}

export default Patent
